<template>
  <div class="content">
    <div class="left-top-main-three-title">
      建筑工人<span class="sup-text"> {{ ownerInfo.workerNum }} </span>人
    </div>
    <div class="left-top-main-three-polo">
      <div class="left-top-main-three-polo-item">
        <dv-water-level-pond :config="waterPoloConfigOne" class="water-polo" />
        <div class="pond-text">出勤率</div>
      </div>
      <div class="left-top-main-three-polo-item">
        <dv-water-level-pond :config="waterPoloConfigTwo" class="water-polo" />
        <div class="pond-text">培训率</div>
      </div>
      <div class="left-top-main-three-polo-item">
        <dv-water-level-pond
          :config="waterPoloConfigThree"
          class="water-polo"
        />
        <div class="pond-text">特殊工种</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: '',
  components: {},
  computed: {
    ...mapState('owner', {
      ownerInfo: (state) => state.ownerInfo,
      workers: (state) => state.workers
    })
  },
  data() {
    return {
      waterPoloConfigOne: {
        data: [0],
        shape: 'round',
        colors: ['#28CAFB'],
        waveNum: 2,
        waveHeight: 10
      },
      waterPoloConfigTwo: {
        data: [0],
        shape: 'round',
        colors: ['#6165FD'],
        waveNum: 2,
        waveHeight: 10
      },
      waterPoloConfigThree: {
        data: [0],
        shape: 'round',
        colors: ['#2ACFFF'],
        waveNum: 2,
        waveHeight: 10
      }
    }
  },
  watch: {
    workers: {
      handler(newVal, oldVale) {
        this.waterPoloConfigOne.data = [newVal.totalOneNum]
        this.waterPoloConfigTwo.data = [newVal.totalTwoNum]
        this.waterPoloConfigThree.data = [newVal.totalThreeNum]
        this.waterPoloConfigOne = { ...this.waterPoloConfigOne }
        this.waterPoloConfigTwo = { ...this.waterPoloConfigTwo }
        this.waterPoloConfigThree = { ...this.waterPoloConfigThree }
      },
      // immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-top: 10px;
  width: 403px;
  .left-top-main-three-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #68bce3;
    opacity: 0.9;
    .sup-text {
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #dca23f;
      opacity: 0.9;
    }
  }
  .left-top-main-three-polo {
    display: flex;
    margin-top: 18px;
    .left-top-main-three-polo-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 27px;
      .pond-text {
        text-align: center;
        margin-top: 5px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.8;
      }
      .water-polo {
        width: 112px;
        height: 112px;
      }
    }
  }
}
</style>
