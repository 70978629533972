<template>
  <div class="content">
    <div class="left-top-main-two-one">
      <div class="left-top-main-two-one-num">{{ ownerInfo.managerNum }}</div>
      <div class="left-top-main-two-one-text">管理人员</div>
    </div>
    <div class="left-top-main-two-two">
      <circular-graph
        :stroke-width="ringConfigOne.strokeWidth"
        :stroke-color="ringConfigOne.strokeColor"
        :trail-width="ringConfigOne.trailWidth"
        :trail-color="ringConfigOne.trailColor"
        :percent="attendance === 0 ? 1 : attendance"
      >{{ attendance }}%</circular-graph>
      <div class="pond-text">出勤率</div>
    </div>
    <!-- <div class="left-top-main-two-two">
      <circular-graph
        :strokeWidth="ringConfigTwo.strokeWidth"
        :strokeColor="ringConfigTwo.strokeColor"
        :trailWidth="ringConfigTwo.trailWidth"
        :trailColor="ringConfigTwo.trailColor"
        :percent="ringConfigTwo.percent === 0 ? 1 : ringConfigTwo.percent"
        >{{ ringConfigTwo.percent }}%</circular-graph
      >
      <div class="pond-text">培训率</div>
    </div> -->
  </div>
</template>

<script>
import circularGraph from '@/components/circular-graph'
import { mapState } from 'vuex'
export default {
  components: {
    circularGraph
  },
  computed: {
    ...mapState('owner', {
      ownerInfo: (state) => state.ownerInfo,
      attendance: (state) => state.attendance
    })
  },
  data() {
    return {
      ringConfigOne: {
        strokeWidth: 8, // 进度线条宽度
        strokeColor: '#4FC5EA', // 进度线条颜色
        trailWidth: 4, // 背景线条宽度
        trailColor: '#666666' // 背景线条圆环颜色
      }
      // ringConfigTwo: {
      //   strokeWidth: 8, // 进度线条宽度
      //   strokeColor: '#C94ED0', // 进度线条颜色
      //   trailWidth: 4, // 背景线条宽度
      //   trailColor: '#666666', // 背景线条圆环颜色
      //   percent: 30 // 进度值
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 403px;
  height: 131px;
  display: flex;
  align-items: center;
  margin-top: 22px;
  .left-top-main-two-one {
    flex: 1.5;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .left-top-main-two-one-num {
      font-size: 36px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #dca23f;
    }
    .left-top-main-two-one-text {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #68bce3;
      opacity: 0.9;
      margin-top: 5px;
    }
  }
  .left-top-main-two-two {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pond-text {
      margin-top: 5px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.8;
    }
  }
}
</style>
