<template>
  <div class="content">
    <!-- 标题 -->
    <div class="child-page-title">
      <h3>工资支出</h3>
    </div>
    <div class="right-top-money f">
      <div class="right-top-money-one f f-j-c f-a-c">
        {{ grpupSalaryInfo.accumulativeSalariesPayable | handleMoney }}W
      </div>
      <div class="right-top-money-two f f-j-c f-a-c">
        {{ grpupSalaryInfo.accumulativeActuallyPaidSalary | handleMoney }}W
      </div>
      <div class="right-top-money-three f f-j-c f-a-c">
        {{ grpupSalaryInfo.accumulativeUnpaidSalary | handleMoney }}W
      </div>
    </div>
    <div class="right-top-money-text f">
      <div class="text-one">开累应付</div>
      <div class="text-two">开累实付</div>
      <div class="text-three">开累未付</div>
    </div>
    <dv-scroll-board class="table c-p" :config="tableConfig" @click="onClick" />
  </div>
</template>

<script>
import { separate } from "@/utils/index";
import { mapState } from "vuex";
export default {
  name: "",
  components: {},
  computed: {
    ...mapState("owner", {
      grpupSalaryInfo: (state) => state.grpupSalaryInfo,
      PrjSalaryStatsList: (state) => state.PrjSalaryStatsList,
    }),
  },
  filters: {
    handleMoney(value) {
      const num = Number.parseInt(value / 10000);
      // 通过千位分隔符分隔
      return separate(num);
    },
  },
  data() {
    return {
      tableConfig: {
        headerHeight: 50,
        headerBGC: "rgba(0,255,255,.3)",
        oddRowBGC: "rgba(0,255,255,.2)",
        evenRowBGC: "",
        align: ["center", "center", "center", "center"],
        carousel: "single",
        // columnWidth: [150],
        header: ["项目名称", "应付工资", "实付工资", "未付工资"],
        data: [],
      },
    };
  },
  watch: {
    PrjSalaryStatsList: {
      handler(newVal, oldVal) {
        this.tableConfig.data = newVal.map((item, index) => {
          return [
            item.abbreviation || item.name,
            separate(item.accumulativeSalariesPayable / 10000) + "W",
            separate(item.accumulativeActuallyPaidSalary / 10000) + "W",
            separate(item.accumulativeUnpaidSalary / 10000) + "W",
          ];
        });
        this.tableConfig = { ...this.tableConfig };
      },
    },
  },
  methods: {
    /**
     * 项目轮播点击事件
     * @param {Object} item 项目信息
     */
    onClick(item) {
      const projectId = this.PrjSalaryStatsList[item.rowIndex].id;
      this.$store.dispatch("user/setProjectId", projectId).then(() => {
        this.$router.push("/project");
      });
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .dv-scroll-board .header .header-item {
  font-size: 20px !important;
  font-family: Microsoft YaHei important !important;
  font-weight: 300 !important;
  color: #21d3ff !important;
}
::v-deep .dv-scroll-board .rows .ceil {
  font-size: 20px !important;
  font-family: Microsoft YaHei !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  opacity: 0.8 !important;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .right-top-money {
    margin-top: 20px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    // font-weight: bold;
    color: #ffffff;
    opacity: 0.9;
    .right-top-money-one {
      padding: 0 10px;
      margin-left: 64px;
      min-width: 81px;
      height: 54px;
      background: #108846;
      border-radius: 8px;
    }
    .right-top-money-two {
      padding: 0 10px;
      min-width: 81px;
      height: 54px;
      background: #6268b3;
      border-radius: 8px;
      margin-left: 43px;
    }
    .right-top-money-three {
      padding: 0 10px;
      min-width: 81px;
      height: 54px;
      background: #00699f;
      border-radius: 8px;
      margin-left: 43px;
    }
  }
  .right-top-money-text {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    // border: $border;
    color: #21d3ff;
    margin-top: 14px;
    .text-one {
      margin-left: 83px;
    }
    .text-two {
      margin-left: 70px;
    }
    .text-three {
      margin-left: 70px;
    }
  }
  .table {
    // border: $border;
    height: 293px;
    margin-top: 26px;
  }
}
</style>
