<template>
  <div class="content">
    <!-- 标题 -->
    <div class="child-page-title">
      <h3>设备实时状态</h3>
    </div>
    <div class="left-bottom-main">
      <!-- 左下第一部分状态 -->
      <div class="left-bottom-main-status">
        <div class="status-item f f-j-c f-a-c">
          <div class="status-online" />
          <div>在线</div>
        </div>
        <div class="status-item f f-j-c f-a-c">
          <div class="status-warning" />
          <div>报警</div>
        </div>
        <div class="status-item f f-j-c f-a-c">
          <div class="status-offline" />
          <div>离线</div>
        </div>
      </div>
      <!-- 左下第二部分 -->
      <div class="left-bottom-main-data f f-c">
        <el-scrollbar class="el-scrollbar">
          <template v-for="(item, index) in statusList">
            <div :key="index" class="status-info f">
              <div class="info-name f">
                <template
                  v-for="(text, index) in item.deviceTypeTxt.replace(/\s/g, '')"
                >
                  <span :key="index" class="f-1"
                    >{{ text
                    }}{{
                      index === item.deviceTypeTxt.length - 1 ? ":" : ""
                    }}</span
                  >
                </template>
              </div>
              <div class="info-data f">
                <div
                  v-if="item.onLine !== 0"
                  :style="`flex:${item.onLine}`"
                  class="info-data-online f f-j-c f-a-c"
                >
                  {{ item.onLine }}
                </div>
                <div
                  v-if="item.callAlarm !== 0"
                  :style="`flex:${item.callAlarm};${
                    item.onLine > 0 ? ' margin-left: 4px' : ''
                  }`"
                  class="info-data-warning f f-j-c f-a-c"
                >
                  {{ item.callAlarm }}
                </div>
                <div
                  v-if="item.offLine !== 0"
                  :style="`flex:${item.offLine};${
                    item.onLine > 0 || item.callAlarm > 0
                      ? ' margin-left: 4px'
                      : ''
                  }`"
                  class="info-data-offline f f-j-c f-a-c"
                >
                  {{ item.offLine }}
                </div>
              </div>
              <div class="info-num f f-j-c f-a-c">
                {{ item.onLine + item.callAlarm + item.offLine }}个
              </div>
            </div>
          </template>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  components: {},
  computed: {
    ...mapState("owner", {
      groupdevices: (state) => state.groupdevices,
    }),
  },
  data() {
    return {
      statusList: [],
    };
  },
  watch: {
    groupdevices: {
      handler(newVal, oldVal) {
        // console.log(newVal);
        this.statusList = newVal;
      },
      deep: true,
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-scrollbar {
  height: 100%;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .left-bottom-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .left-bottom-main-status {
      display: flex;
      align-items: center;
      margin-top: 12px;
      padding-left: 138px;
      .status-item {
        display: flex;
        margin-right: 18px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #68bce3;
        .status-online {
          width: 14px;
          height: 14px;
          background: #4579e3;
          margin-right: 4px;
        }
        .status-warning {
          width: 14px;
          height: 14px;
          background: #f25656;
          margin-right: 4px;
        }
        .status-offline {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          background: #e07734;
        }
      }
    }
    .left-bottom-main-data {
      height: 235px;
      margin-top: 24px;
      padding-left: 22px;
      .status-info {
        margin-bottom: 11px;
        .info-name {
          width: 91px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #68bce3;
          text-align: right;
        }
        .info-data {
          width: 251px;
          margin-left: 13px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #ffffff;
          .info-data-online {
            background: #4579e3;
          }
          .info-data-warning {
            background: #f25656;
          }
          .info-data-offline {
            background: #e07734;
          }
        }
        .info-num {
          margin-left: 12px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #68bce3;
        }
      }
    }
  }
}
</style>
