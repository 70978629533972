<template>
  <div>
    <!-- 左侧抽屉 -->
    <div class="drawer-box-left animated bounceIn" :style="`left:${left}px`">
      <!-- 右侧上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-left-top-box"
      >
        <slot name="leftDrawerTopContent" />
      </dv-border-box-7>
      <!-- 右侧下半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-left-bottom-box"
      >
        <slot name="leftDrawerBottomContent" />
      </dv-border-box-7>
      <!-- 抽屉开关图标 -->
      <div class="left-arrow" @click="onDrawerLeft">
        <i
          :class="`${
            leftDrawerOpen ? 'el-icon-arrow-left' : 'el-icon-arrow-right'
          } icon-arrow`"
        />
      </div>
    </div>
    <!-- 右侧抽屉 -->
    <div class="drawer-box-right animated bounceIn" :style="`right:${right}px`">
      <!-- 右侧上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-right-top-box"
      >
        <slot name="rightDrawerTopContent" />
      </dv-border-box-7>
      <!-- 右侧下半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-right-bottom-box"
      >
        <slot name="rightDrawerBottomContent" />
      </dv-border-box-7>
      <!-- 抽屉开关图标 -->
      <div class="right-arrow" @click="onDrawerRight">
        <i
          :class="`${
            rightDrawerOpen ? 'el-icon-arrow-right' : 'el-icon-arrow-left'
          } icon-arrow`"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      left: 15,
      leftDrawerOpen: true,
      right: 15,
      rightDrawerOpen: true,
    };
  },
  methods: {
    /**
     * 抽屉左侧展开和收起事件
     */
    onDrawerLeft() {
      if (this.leftDrawerOpen) {
        this.left = -460;
      } else {
        this.left = 15;
      }
      this.leftDrawerOpen = !this.leftDrawerOpen;
    },
    /**
     * 抽屉右侧展开和收起事件
     */
    onDrawerRight() {
      if (this.rightDrawerOpen) {
        this.right = -511;
      } else {
        this.right = 15;
      }
      this.rightDrawerOpen = !this.rightDrawerOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
// 左侧抽屉样式
.drawer-box-left {
  height: 924px;
  width: 454px;
  position: fixed;
  top: 125px;
  z-index: 1;
  transition: left 0.4s;
  opacity: 0.92;
  .drawer-box-left-top-box {
    width: 100%;
    height: 583px;
    border: 0px;
    background: rgba(100, 116, 211, 0.04);
    background-color: $drawer-bg-color !important;
    opacity: 0.8;
  }
  .drawer-box-left-bottom-box {
    margin-top: 12px;
    width: 100%;
    height: 332px;
    border: 0px;
    background: rgba(100, 116, 211, 0.04);
    background-color: $drawer-bg-color !important;
    opacity: 0.8;
  }
  .left-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 61px;
    background-color: #0c2e68;
    opacity: 0.8;
    border-radius: 2px;
    position: absolute;
    top: 459px;
    left: 470px;
    cursor: pointer;
    transition: 0.3s ease background-color;
  }
}
// 右侧抽屉样式
.drawer-box-right {
  height: 924px;
  width: 513px;
  position: fixed;
  top: 125px;
  z-index: 1;
  opacity: 0.92;
  transition: right 0.4s;
  .drawer-box-right-top-box {
    width: 100%;
    height: 471px;
    border: 0px;
    background: rgba(100, 116, 211, 0.04);
    background-color: $drawer-bg-color !important;
    opacity: 0.8;
  }
  .drawer-box-right-bottom-box {
    margin-top: 12px;
    width: 100%;
    height: 440px;
    border: 0px;
    background: rgba(100, 116, 211, 0.04);
    background-color: $drawer-bg-color !important;
    opacity: 0.8;
  }
  .right-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 61px;
    background-color: #0c2e68;
    opacity: 0.8;
    border-radius: 2px;
    position: absolute;
    top: 459px;
    right: 522px;
    cursor: pointer;
    transition: 0.3s ease background-color;
  }
}
// 箭头样式
.icon-arrow {
  font-size: 26px;
  font-family: SimHei;
  font-weight: 400;
  color: #8ae2fe;
}
</style>
