<template>
  <div class="content">
    <!-- 标题 -->
    <div class="child-page-title">
      <div class="child-page-title-div">
        <el-image
          class="head-img"
          :src="require('../../../assets/image/warning-img.png')"
          :fit="'cover'"
        />
        <h3>报警提示</h3>
      </div>
    </div>
    <div v-if="alrmList && alrmList.length > 0" class="warning-list">
      <vue-seamless-scroll
        :data="alrmList"
        :class-option="classOption"
        class="seamless-scroll"
      >
        <template v-for="(item, index) in alrmList">
          <div :key="index" class="list-itme-content">
            <div class="list-item">
              <span class="list-item-tilte">.[{{ item.name }}]</span>
              {{ item.content }}
            </div>
            <div class="item-time f f-j-e">{{ item.time }}</div>
          </div>
        </template>
      </vue-seamless-scroll>
    </div>
    <div v-else class="not-have-warning f f-j-c f-a-c">暂无报警信息!</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  components: {},
  computed: {
    ...mapState("owner", {
      ownerAlarmLogList: (state) => state.ownerAlarmLogList,
    }),
  },
  data() {
    return {
      alrmList: [],
      classOption: {
        step: 0.5, // 数值越大速度滚动越快。
        limitMoveNum: 4, // 开启无缝滚动的数据量。
        hoverStop: true, // 是否启用鼠标 hover 控制。
      },
    };
  },
  watch: {
    ownerAlarmLogList: {
      handler(newVal, oldVale) {
        this.alrmList = newVal;
      },
    },
  },
};
</script>

<style lang='scss' scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.child-page-title-div{
  display: flex;
  margin-left: 36% !important;
}
.head-img {
  width: 25px;
  height: 26px;
  margin-top: 3px;
  margin-right: 2px;
}
.head-text {
  padding-left: 12px;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #21d3ff;
}
.warning-list {
  height: 395px;
  margin-top: 2px;
  overflow: hidden;
  .seamless-scroll {
    height: 100%;
  }
  .list-itme-content {
    padding-left: 29px;
    padding-right: 28px;
    margin-bottom: 10px;
    .list-item {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.6);
      line-height: 30px;
      overflow: hidden; /*必须结合的属性,当内容溢出元素框时发生的事情*/
      text-overflow: ellipsis; /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
      display: -webkit-box; /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
      -webkit-line-clamp: 2; /*用来限制在一个块元素显示的文本的行数。*/
      -webkit-box-orient: vertical; /*必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      .list-item-tilte {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #dca23f;
        opacity: 1 !important;
      }
    }
    .item-time {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #1dbbe8;
      line-height: 30px;
      opacity: 0.6;
    }
  }
}
.not-have-warning {
  height: 346px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.6);
}
</style>
