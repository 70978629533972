<template>
  <div class="home">
    <child-page-head
      class="home-top-main"
      :title="ownerInfo.shortName || ownerInfo.name"
      :isGoBack="false"
    />
    <!-- 抽屉组件 -->
    <drawer>
      <!-- 左侧内容-上 -->
      <div slot="leftDrawerTopContent">
        <!-- 左上标题 -->
        <div class="left-title text-ellipsis">
          {{ ownerInfo.shortName || ownerInfo.name }}
        </div>
        <div class="left-top-main">
          <!-- 左上第一部分 -->
          <div class="left-top-one-content">
            <drawer-left-top-one-content />
            <drawer-left-top-two-content />
          </div>
          <!-- 分割线 -->
          <div class="fgx" style="margin:0 auto;"/>
          <!-- 左上第二部分 -->
          <div class="left-top-main-three-content">
            <drawer-left-top-three-content />
          </div>
        </div>
      </div>
      <!-- 左侧内容-下 -->
      <div slot="leftDrawerBottomContent">
        <drawer-left-bottom-content />
      </div>
      <!-- 右侧内容-上 -->
      <div slot="rightDrawerTopContent">
        <drawer-right-top-content />
      </div>
      <!-- 右侧内容-下 -->
      <div slot="rightDrawerBottomContent">
        <drawer-right-bottom-content />
      </div>
    </drawer>
    <!-- 百度地图 -->
    <baidu-map
      class="map"
      :map-click="false"
      :map-style="mapStyle"
      :scroll-wheel-zoom="true"
      :center="center"
      :zoom="zoom"
      :max-zoom="zoom"
      @ready="initMap"
      @mouseover="mapMouseover"
    >
      <template v-for="(item, index) in mapMakerList">
        <bm-marker
          :key="index"
          :icon="{
            url: require('../../assets/image/maker.png'),
            size: { width: 37, height: 46 },
          }"
          :offset="{ width: 0, height: -15 }"
          :position="{ lng: item.lng, lat: item.lat }"
          :dragging="false"
          @click="onClick(item)"
          @mouseover="makerMouseover(item, index)"
        />
      </template>
      <bm-info-window
        :offset="{ width: 10, height: 25 }"
        :position="infoWindowPosition"
        :show="infoWindow"
      >
        <div class="info-box f" @click="onClick(infoWindowItem)">
          <div class="info-box-main-left f f-c f-a-c">
            <el-image
              class="avter"
              :src="require('../../assets/image/avter.png')"
              :fit="'contain'"
            />
            <div class="left-text">{{ infoWindowItem.totalNum }}人</div>
          </div>
          <div class="info-box-main-right">
            <div class="right-title">{{ infoWindowItem.abbreviation }}</div>
            <div class="right-content">
              <div>
                项目状态:<span class="margin-left-8">{{
                  infoWindowItem.prjStatus | handlePrjStatus
                }}</span>
              </div>
              <div class="f">
                <div>
                  项目经理:<span class="margin-left-8">{{
                    infoWindowItem.pmName || "--"
                  }}</span>
                </div>
                <div class="margin-left-10">
                  安全管理员:<span class="margin-left-8">{{
                    infoWindowItem.saName || "--"
                  }}</span>
                </div>
              </div>
              <div class="f">
                <div>
                  技术管理员:<span class="margin-left-8">{{
                    infoWindowItem.taName || "--"
                  }}</span>
                </div>
                <!-- <div class="margin-left-10">
                  栋行长:<span class="margin-left-8">张扬张扬</span>
                </div> -->
              </div>
            </div>
          </div>
          <span class="triangle" />
        </div>
      </bm-info-window>
    </baidu-map>
    <loading v-if="loading" />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import drawer from "@/components/drawer";
import childPageHead from "@/components/child-page-head";
import drawerLeftTopOneContent from "./components/drawer-left-top-one-content";
import drawerLeftTopTwoContent from "./components/drawer-left-top-two-content";
import drawerLeftTopThreeContent from "./components/drawer-left-top-three-content";
import drawerLeftBottomContent from "./components/drawer-left-bottom-content";
import drawerRightTopContent from "./components/drawer-right-top-content";
import drawerRightBottomContent from "./components/drawer-right-bottom-content";
import { mapState } from "vuex";
import { getPrjMaps } from "../../api/owner";
export default {
  name: "Home",
  components: {
    Loading,
    drawer,
    childPageHead,
    drawerLeftTopOneContent,
    drawerLeftTopTwoContent,
    drawerLeftTopThreeContent,
    drawerLeftBottomContent,
    drawerRightTopContent,
    drawerRightBottomContent,
  },
  computed: {
    ...mapState("owner", {
      ownerInfo: (state) => state.ownerInfo,
    }),
  },
  filters: {
    handlePrjStatus(value) {
      switch (value) {
        case 1:
          return "筹备";
          break;
        case 2:
          return "在建";
          break;
        case 3:
          return "完工";
          break;
        case 4:
          return "停工";
          break;
      }
    },
  },
  data() {
    return {
      loading: false,
      infoWindowPosition: { lng: 0, lat: 0 },
      infoWindow: false,
      center: { lng: 0, lat: 0 },
      zoom: 13,
      mapMakerList: [],
      infoWindowItem: {},
      // 地图类型主题样式配置
      mapStyle: {
        styleJson: [
          {
            featureType: "water", // 水
            elementType: "all",
            stylers: {
              color: "#002051",
            },
          },
          {
            featureType: "highway", // 公路
            elementType: "geometry.fill",
            stylers: {
              color: "#000000",
            },
          },
          {
            featureType: "highway", // 公路
            elementType: "geometry.stroke",
            stylers: {
              color: "#147a92",
            },
          },
          {
            featureType: "arterial", // 主干道
            elementType: "geometry.fill",
            stylers: {
              color: "#051E36",
            },
          },
          {
            featureType: "arterial", // 主干道
            elementType: "geometry.stroke",
            stylers: {
              color: "#051E36",
            },
          },
          {
            featureType: "local", // 地方，局部
            elementType: "geometry",
            stylers: {
              color: "#000F1E",
            },
          },
          {
            featureType: "land", // 土地
            elementType: "all",
            stylers: {
              color: "#000F1E",
            },
          },
          {
            featureType: "railway", // 铁路
            elementType: "geometry.fill",
            stylers: {
              color: "#051E36",
            },
          },
          {
            featureType: "railway", // 铁路
            elementType: "geometry.stroke",
            stylers: {
              color: "#051E36",
            },
          },
          {
            featureType: "subway", // 地铁
            elementType: "geometry",
            stylers: {
              color: "#051E36",
            },
          },
          {
            featureType: "building", // 建筑
            elementType: "geometry.fill",
            stylers: {
              color: "#000F1E",
            },
          },
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: {
              color: "#857f7f",
            },
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: {
              color: "#000000",
            },
          },
          {
            featureType: "building", // 建筑
            elementType: "geometry",
            stylers: {
              color: "#000F1E",
            },
          },
          {
            featureType: "green", // 绿色
            elementType: "geometry",
            stylers: {
              color: "#000F1E",
            },
          },
          {
            featureType: "boundary", // 边界
            elementType: "all",
            stylers: {
              color: "#243169",
            },
          },
          {
            featureType: "manmade", // 人造的
            elementType: "geometry",
            stylers: {
              color: "#000F1E",
            },
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "all",
            elementType: "labels.icon",
            stylers: {
              visibility: "off",
            },
          },
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: {
              color: "#21D3FF",
              visibility: "on",
            },
          },
        ],
      },
    };
  },
  created() {
    this.loading = true;
    // 获取企业信息
    const h1 = this.$store.dispatch("owner/getData");
    Promise.all([h1]).then((res) => {
      this.loading = false;
    });
    // // 获取企业信息
    // const h1 = this.$store.dispatch("owner/getOwnerInfoDatas");
    // // 获取企业管理人员出勤率
    // const h2 = this.$store.dispatch("owner/getOwnerAttendRateDatas");
    // // 获取企业建筑工人出勤率、培训率、特殊工种统计
    // const h3 = this.$store.dispatch("owner/getWorkersDatas");
    // // 获取设备状态管理
    // const h4 = this.$store.dispatch("owner/getGroupDeviceDatas");
    // // 获取开累应付工资、实付工资、未付工资
    // const h5 = this.$store.dispatch("owner/getGrpupSalaryInfoDatas");
    // // 获取企业关联项目工资数据列表
    // const h6 = this.$store.dispatch("owner/getPrjSalaryStatsList");
    // // 获取企业关联设备报警列表
    // const h7 = this.$store.dispatch("owner/getOwnerAlarmLogList");
    // Promise.all([h1, h2, h3, h4, h5, h6, h7]).then((res) => {
    //   this.loading = false;
    // });
  },
  methods: {
    /**
     * 获取地图坐标数据
     */
    getPrjMapsList() {
      getPrjMaps().then((res) => {
        const { data } = res;
        this.mapMakerList = data.items;
        this.center.lng = this.mapMakerList[0].lng;
        this.center.lat = this.mapMakerList[0].lat;
      });
    },
    /**
     * 地图初始化完成
     */
    initMap({ BMap, map }) {
      this.getPrjMapsList();
    },
    /**
     * 坐标和信息窗体点击事件
     * @param {Object} item 项目信息
     */
    onClick(item) {
      // console.log(item);
      this.$store.dispatch("user/setProjectId", item.id).then(() => {
        this.$router.push("/project");
      });
    },
    /**
     * 鼠标移入坐标点区域-打开信息窗口
     * @param {Object} item 坐标对象
     * @param {Number} index 坐标索引
     */
    makerMouseover(item, index) {
      this.infoWindowItem = { ...item };
      this.infoWindowPosition.lng = item.lng;
      this.infoWindowPosition.lat = item.lat;
      this.infoWindow = true;
    },
    /**
     * 鼠标移入地图事件
     */
    mapMouseover() {
      if (!this.infoWindow) return;
      this.infoWindow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .anchorBL {
  display: none !important;
}
::v-deep .BMap_pop > img {
  display: none;
}
::v-deep .BMap_shadow > div {
  display: none;
}
::v-deep .BMap_pop > div > img {
  display: none;
}
::v-deep .BMap_pop > div:nth-child(1) {
  display: none;
}
::v-deep .BMap_pop > div:nth-child(3) {
  display: none;
}
::v-deep .BMap_pop > div:nth-child(5) {
  display: none;
}
::v-deep .BMap_pop > div:nth-child(7) {
  display: none;
}
::v-deep .BMap_pop > div:nth-child(8) {
  display: none;
}
::v-deep .BMap_pop > div:nth-child(9) {
  height: 184px !important;
}
::v-deep .BMap_top {
  display: none;
}
::v-deep .BMap_center {
  display: none;
}
::v-deep .BMap_bottom {
  display: none;
}
.margin-left-8 {
  margin-left: 8px;
}
.margin-left-10 {
  margin-left: 10px;
}
.avter {
  width: 40px;
  height: 44px;
}
.info-box-main-left {
  padding-top: 33px;
  padding-left: 36px;
  .left-text {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #06a44d;
    margin-top: 10px;
  }
}
.info-box-main-right {
  margin-left: 25px;
  .right-title {
    margin-top: 23px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #f1af3c;
    letter-spacing: 2px;
  }
  .right-content {
    margin-top: 14px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #68bce3;
    opacity: 0.9;
  }
}
.info-box {
  cursor: pointer;
  position: relative;
  width: 359px;
  height: 163px;
  background: #002f54;
  border: 1px solid #20b5e2;
  opacity: 0.9;
  border-radius: 10px;
  .triangle,
  .triangle:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    position: absolute;
    left: 37%;
    top: 100%;
    display: block;
  }

  .triangle {
    border-top-color: #20b5e2 !important;
    border-width: 21px;
  }

  .triangle:after {
    content: "";
    border-width: 20px;
    border-top-color: #002f54;
    margin-top: -21px;
    margin-left: -20px;
  }
}
.map {
  width: 100vw;
  height: 100vh;
}
.home-top-main {
  position: fixed;
  z-index: 1;
}
.head-ztb-tabs {
  margin: 0 auto;
}
.left-title {
  height: 62px;
  line-height: 62px;
  text-align: center;
  background: linear-gradient(90deg, #174091, #1d8ccb);
  font-size: 24px;
  font-family: SimHei;
  font-weight: 400;
  color: white;
}
.left-top-main {
  height: 521px;
  opacity: 0.92;
  align-items: center;
  .left-top-one-content {
    width: 454px;
    height: 307px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.92;
  }
  .left-top-main-three-content {
    background-image: url("../../assets/image/drawer.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.92;
  }
}
</style>
