<template>
  <div class="content">
    <div class="left-top-one-image">
      <el-image
        class="left-top-one-image"
        :src="ownerInfo.logo"
        :fit="'cover'"
      />
    </div>
    <div class="left-top-one-text">
      <div>
        在建项目数量:<span class="margin-left-8"
          >{{ ownerInfo.totalProjNum }} 个</span
        >
      </div>
      <div>
        在建建筑面积:<span class="margin-left-8"
          >{{ ownerInfo.buildingArea }} m²</span
        >
      </div>
      <!-- <div class="left-top-one-text-s">
        <div>
          工人总数:<span class="margin-left-8"
            >{{ ownerInfo.workerNum }}人</span
          >
        </div>
        <div class="margin-left-10">
          管理人数:<span class="margin-left-8"
            >{{ ownerInfo.managerNum }}人</span
          >
        </div>
      </div> -->
      <div>
        参建单位数量:<span class="margin-left-8"
          >{{ ownerInfo.totalCorpNum }} 个</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("owner", {
      ownerInfo: (state) => state.ownerInfo,
    }),
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 403px;
  height: 100px;
  display: flex;
  align-items: center;
  margin-top: 41px;
  .left-top-one-image {
    width: 100px;
    height: 100px;
    // opacity: 0.8;
  }
  .left-top-one-text {
    margin-left: 27px;
    width: 282px;
    height: 99px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0.8;
    .left-top-one-text-s {
      display: flex;
    }
  }
  .margin-left-8 {
    margin-left: 8px;
  }
  .margin-left-10 {
    margin-left: 10px;
  }
}
</style>
